<template>
  <div class="job">
    <van-nav-bar
      title="今日报工"
      left-text="返回"
      left-arrow
      fixed
      @click-left="onClickLeft"
    />
    <scroll class="product_scroll" :onLoadMore="onLoadMore">
      <div class="output_k" v-if="todayReportWork.length == 0">
        <div class="conten_empty_img">
          <img src="../../common/img/kong.png" />
        </div>
        <div class="establish">
          今天还没有报工任务哦，快去
          <a @click="toWorkOrder()">
            <router-link to="">新建报工 </router-link>
          </a>
          吧!
        </div>
      </div>
      <div v-else>
        <div class="job_item" v-for="item in todayReportWork" :key="item.id">
          <div class="job_item_header">
            <div class="job_item_header_stamp">
              {{ item.createUser }}
            </div>
            <!-- <div class="job_item_header_enter">吉时</div>
            <div class="job_item_header_line">|</div> -->
            <div class="job_item_header_process">{{ item.wpCode }}</div>
          </div>
          <div class="job_item_name">
            <div>产品名称:</div>
            <div>{{ item.productName }}</div>
          </div>
          <div class="job_item_content">
            <div class="item">
              <div class="item_name">总数</div>
              <div class="item_num">{{ item.woPlanCounts }}</div>
            </div>
            <div class="item">
              <div class="item_name">良品数</div>
              <div class="item_num">
                {{ item.woGoodProductCounts }}
              </div>
            </div>
            <div class="item">
              <div class="item_name">不良品数</div>
              <div class="item_num stamp">
                {{ item.woBadProductCounts }}
              </div>
            </div>
            <div class="item">
              <div class="item_name">不良率</div>
              <div class="item_num work_num">{{ item.bad }}%</div>
            </div>
          </div>
        </div>
      </div>
    </scroll>
  </div>
</template>
<script>
import scroll from "../../components/scroll/index";
import { ChangePageTitle } from "../../common/js/upTitle";
import { mapGetters } from "vuex";
import { getTodayReportWork } from "@/api/work";

export default {
  computed: {
    ...mapGetters(["departmentCode"]),
  },
  data() {
    return {
      todayReportWork: [],
    };
  },
  created() {
    ChangePageTitle("今日报工");
    this.getTodayReportWork();
  },
  methods: {
    //跳转去新建工单
    toWorkOrder() {
      window.location.href = "/reportWork1";
    },
    onClickLeft() {
      this.$router.push("/");
    },
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) {
          return;
        }
        this.page = this.page + 1;
        this.getListData();
        done();
      }, 200);
    },
    async getTodayReportWork() {
      let params = {
        departmentCode: this["departmentCode"],
      };
      const res = await getTodayReportWork(params);
      const dataInfo = res.records;
      const temp = [];
      if (dataInfo.length > 0) {
        for (let i = 0; i < dataInfo.length; i++) {
          if (
            dataInfo[i].woBadProductCounts > 0 &&
            dataInfo[i].woGoodProductCounts > 0
          ) {
            temp[i] = {
              bad:
                (parseInt(dataInfo[i].woBadProductCounts) /
                  parseInt(
                    dataInfo[i].woGoodProductCounts == 0
                      ? 1
                      : dataInfo[i].woGoodProductCounts
                  )) *
                100,
              ...dataInfo[i],
            };
          } else {
            temp[i] = {
              bad: 0,
              id: dataInfo[i].id,
              createUser: dataInfo[i].createUser,
              wpCode: dataInfo[i].wpCode,
              productName: dataInfo[i].productName,
              woPlanCounts:
                dataInfo[i].woPlanCounts > 0 ? dataInfo[i].woPlanCounts : 0,
              woGoodProductCounts: 0,
              woBadProductCounts: 0,
            };
          }
        }
      }
      this.todayReportWork = temp;
    },
  },
  components: {
    scroll,
  },
};
</script>
<style lang="less" scoped>
.job {
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;

  .product_scroll {
    padding-top: 100px;

    .output_k {
      width: 100vw;

      .conten_empty_img {
        margin: auto;
        width: 40vw;
        margin-top: 30%;

        img {
          width: 100%;
        }
      }

      .establish {
        font-size: 30px;
        text-align: center;
        line-height: 1;
        color: #999;
        margin-top: 40px;

        a {
          color: blue;
        }
      }
    }

    .job_item {
      width: 95%;
      margin: 20px auto 0px auto;
      background-color: #fff;
      border-radius: 10px;
      padding: 16px 20px;
      font-size: 30px;

      .job_item_header {
        display: flex;
        align-items: center;
        color: black;
        font-size: 42px;

        .job_item_header_stamp {
          background-color: blue;
          border-radius: 10px;
          color: #fff;
          padding: 4px;
        }

        .job_item_header_enter {
          margin-left: 10px;
        }

        .job_item_header_process {
          margin-left: 10px;
        }
      }

      .job_item_name {
        margin-top: 16px;
        display: flex;

        div:nth-child(2) {
          margin-left: 10px;
        }
      }

      .job_item_content {
        display: flex;
        margin-top: 20px;

        .item {
          flex: 1;
          display: flex;
          flex-direction: column;
          text-align: center;

          .item_num {
            margin-top: 20px;
            color: black;
          }

          .stamp {
            color: red;
          }

          .work_num {
            color: red;
          }
        }
      }
    }
  }
}
</style>
